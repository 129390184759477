import React from 'react'

export default function SecondaryMenu({ links, isVisible, closeMenu }) {
  const className = isVisible ? 'secondary-menu is-visible' : 'secondary-menu'
  return (
    <ul className={className}>
      {links.length && links.map((link) => {
        return (
          <li>
            <a className={'option'} href={link.url} target='_blank' rel='noreferrer'>{link.text}</a>
          </li>
        )
      })}
      <li>
        <button className={'option'} onClick={() => closeMenu()}>{'<< back'}</button>
      </li>
    </ul>
  )
}