import React, { Component } from "react"
import { Link } from 'gatsby';
import { Snowflakes } from '../Snowflake'
import SecondaryMenu from '../SecondaryMenu'
import { Helmet } from "react-helmet"

class Menu extends Component {
  constructor() {
    super()
    this.state = {
      links: [],
      showSecondaryMenu: false
    }
  }

  musicLinks = [
    {url: 'https://open.spotify.com/artist/6lirZ0ouvh4l3fz3HSnIwi', text: 'Spotify'},
    {url: 'https://music.apple.com/us/artist/joseph-chilliams/1119474533', text: 'Apple Music'},
    {url: 'https://soundcloud.com/joseph-chilliams', text: 'SoundCloud'},
    {url: 'https://tidal.com/browse/artist/7887104', text: 'Tidal'}
  ];

  socialLinks = [
    {url: 'https://twitter.com/JosephChilliams', text: 'Twitter'},
    {url: 'https://www.instagram.com/josephchilliams/', text: 'Instagram'}
  ]

  hideSecondaryMenu = () => {
    return this.setState({showSecondaryMenu: false})
  }

  openSecondaryMenu = (links) => {
    return this.setState({showSecondaryMenu: true, links: links})
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Joseph Chilliams</title>
          <meta name='description' content='Official site for Chicago rapper Joseph Chilliams.'/>
        </Helmet>
        <div className={'border-img'}/>
        <nav className={'menu menu-background'}>
          <ul>
            <li><Link to='/' className={'option'}>Home</Link></li>
            <li onClick={() => this.openSecondaryMenu(this.musicLinks)}>
              <button className={'option'}>Music</button>
            </li>
            <li>
              <a className={'option'} href='https://www.youtube.com/channel/UCMDM_2kig7NyjkOvEkry3uQ' target='_blank'
                 rel='noreferrer'>Video</a>
            </li>
            <li>
              <a className={'option'} href='https://josephchilliams.bigcartel.com/' target='_blank' rel='noreferrer'>Merch</a>
            </li>
            <li onClick={() => this.openSecondaryMenu(this.socialLinks)}>
              <button className={'option'}>Social</button>
            </li>
          </ul>
        </nav>
        <SecondaryMenu links={this.state.links} isVisible={this.state.showSecondaryMenu} closeMenu={this.hideSecondaryMenu}/>
        <Snowflakes/>
      </div>
    )
  }
}

export default Menu;
